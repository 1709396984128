
body {
  font-family: 'sans-serif', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  list-style-type: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* ---for common use ----- */

/* background-color: green */
.bg-grenncolor {
  background-color: #04AA6D;
}

/*font color: white */
.fcw {
  color: white
}

/* font color: green **/
.fcg {
  color: #04AA6D;
}

/* for giving space bellow navbar */
.topSpace {
  padding-top: 4rem;
}

.container-fluid {
  padding-bottom: 10px;
}

.btnColorCustome {
  color: #fff;
  background-color: #04AA6D;
}

.btnColorOnHover:hover {
  color: white;
  background-color: #0c8156;
}

.iconUpDownOnOnHover:hover{background-color: rgb(220, 220, 220); cursor: pointer;}

.myBorder {
  border-bottom: 1px solid rgb(229, 228, 228);
}
.spanBg{background-color: #d8dada;}
.cursorType{cursor: pointer;}

/* --- for common use end ----- */

/* dashbord-header  */
 .db-header-help .fa {
  font-size: 1.5rem;
}

.db-header-help .fa:hover {
  color: #04AA6D;
}

.db-header-user .fa {
  font-size: 1.4rem;
}

.db-header-user .fa:hover {
  color: #04AA6D;
}

.db-header-left .fa:hover {
  color: #04AA6D;
}

/* side bar menu */
.sidebar nav .myItem p {
  font-size: 0.9rem;
}

.sidebar nav .myItem .nav-link {
  margin-bottom: 0 !important;
}

.sidebar nav .myItem {
  border-left: 4px solid transparent;
}

.sidebar nav .myItem:hover {
  border-left: 4px solid #04AA6D;
}

.sidebar nav .myItem:hover .nav-link i,
.sidebar nav .myItem:hover .nav-link p {
  color: #04AA6D;
}


/* for multilevel menu */
.sidebar nav .multilevelLink .nav-item p {
  font-size: 0.9rem;
  color: #919eab
}

.sidebar nav .multilevelLink .nav-item {
  border-left: 4px solid transparent;
}

.sidebar nav .multilevelLink .nav-item:hover {
  border-left: 4px solid #04AA6D;
  background-color: rgba(0, 0, 0, .1);
}

.sidebar nav .multilevelLink .nav-item .nav-link {
  margin: 0;
}

.sidebar nav .multilevelLink .nav-item:hover .nav-link p {
  color: #04AA6D;
}

.brand-link .brand-text:hover {
  color: #04AA6D;
}

.sideBarMenu .pushmenu .fa {
  color: #919eab;
  font-size: 1.1rem;
}

.sideBarMenu .pushmenu .fa:hover {
  color: #04AA6D;
}

/* home page -> setup gude */
.setupGuideBtn .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff;
}

.myBorder {
  border-bottom: 1px solid rgb(209, 206, 206);
}

.setupGuideProBar {
  padding: 0 1.3rem 0.8rem 1.3rem;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: rgb(209, 206, 206);
  display: inline-block;
  position: absolute;
  left: 9px;
  width: 1px;
  height: 100%;
  z-index: 400;
}

ul.timeline>li {
  margin: 1rem 0;
  padding-left: 2rem;
}

ul.timeline>li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px dashed rgb(209, 206, 206);
  left: 0rem;
  width: 1.3rem;
  height: 1.3rem;
  z-index: 400;
}

ul.timeline li>a {
  color: grey;
  font-weight: 500;
}

ul.timeline li>a:hover {
  color: #04AA6D;
  ;
  font-weight: 500;
}

.card-dis-p {
  line-height: 1.1rem;
}

.card-heading-p {
  font-size: 1.2rem;
}

/* for add product */
.add-prod .add-media-option {
  border: 1px dashed gray;
  height: 10rem;
  width: 100%;
  border-radius: 0.4rem;
}

.add-prod .add-media-option:hover {
  background-color: rgb(231, 236, 236);
  cursor: pointer;
}

.add-option .iconTrash i,
.add-option .iconSliders i {
  cursor: pointer;
}

/*-- manage modal min height --*/
.myMenageModal-Min-H {
  min-height: 70vh;
}

/* for add product */
.add-prod .add-media-option {
  border: 1px dashed gray;
  height: 10rem;
  width: 100%;
  border-radius: 0.4rem;
}

.add-prod .add-media-option:hover {
  background-color: rgb(231, 236, 236);
  cursor: pointer;
}

.add-option .iconTrash i,
.add-option .iconSliders i {
  cursor: pointer;
}

/* manage modal min height */
.myMenageModal-Min-H {
  min-height: 70vh;
}

.createTransfer ul li {
  cursor: pointer;
}

.myDrop ul li .dropdown-item:active {
  background-color: white;
  color: #000;
}


/* -------------------------------- */
.bg-grenncolor {
  background-color: #04AA6D;
}

/*font color: white */

.fcw {
  color: white
}

/* font color: green **/
.fcg {
  color: #04AA6D;
}

.fcb {
  color: black;
}

.fs12 {
  font-size: 12px;
}

.bgcw {
  background-color: white;
}

.populer {
  background-color: #c4c4c5;
  margin-top: -10px;
}

.choseplan {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: none;
}

.tile {
  width: 80%;
  margin: 60px auto;
}

#tile-1 .tab-pane {
  padding: 15px;
  height: 80px;
}

#tile-1 .nav-tabs {
  position: relative;
  border: none !important;
  background-color: #fff;
  /*   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); */
  border-radius: 6px;
}

#tile-1 .nav-tabs li {
  margin: 0px !important;
}

#tile-1 .nav-tabs li a {
  position: relative;
  margin-right: 0px !important;
  padding: 20px 40px !important;
  font-size: 16px;
  border: none !important;
  color: #333;
}

#tile-1 .slider {
  display: inline-block;
  width: 30px;
  height: 100%;
  border-radius: 3px;
  background-color: #04AA6D;
  position: absolute;
  z-index: 11;
  bottom: 0;
  transition: all .4s linear;

}

.bcg {
  background-color: #04AA6D;
}

#tile-1 .nav-tabs .active {
  background-color: transparent !important;
  border: none !important;
  z-index: 22;
  color: white;
}

.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  background-color: white;
  border-radius: 10px;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.starter {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  background-color: white;
  border-radius: 10px;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.title .nav-item {
  border-right: 1px solid black;
}


.price .header {
  background-color: #111;
  color: white;
  font-size: 25px;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;

}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}

.starter .header {
  background-color: #111;
  color: white;
  font-size: 25px;
}

.starter li {
  border-bottom: 1px solid #eee;
  padding: 20px;

}

.starter .grey {
  background-color: #eee;
  font-size: 20px;
}

.button {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

.alert-primary {
  color: #000;
  background-color: #d7e6f76b;
  border-color: #006fe638;
}

.typeofdisc:hover {
  background-color: rgba(209, 209, 209, 0.486);
}

/* responsiveness */
@media (max-width: 991px) {
  .tile {
    width: 80%;
  }

  #tile-1 .nav-tabs li a {
    padding: 10px 0px !important;
    font-size: 10px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .tile {
    width: 80%;
  }

  #tile-1 .nav-tabs li a {
    padding: 10px 0px !important;
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .tile {
    width: 80%;
  }

  #tile-1 .nav-tabs li a {
    padding: 10px 0px !important;
    font-size: 10px;
  }
}

@media (max-width: 360px) {
  .tile {
    width: 80%;
  }

  #tile-1 .nav-tabs li a {
    padding: 10px 0px !important;
    font-size: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .tile {
    width: 80%;
  }

  #tile-1 .nav-tabs li a {
    padding: 10px 0px !important;
    font-size: 10px;
  }
}

@media screen and (max-width: 600px) {
  .tile {
    width: 80%;
  }

  #tile-1 .nav-tabs li a {
    padding: 10px 0px !important;
    font-size: 10px;
  }
}


.plans label {
  margin-bottom: 0px;
  display: block;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 100%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  padding: 5px;
  padding-left: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e1e2e7;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #252f42;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}


.plans .plan input[type="radio"]:checked+.plan-content:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background: #274bff;
  left: 8px;
  top: 13px;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 3px #0066ff;
}

.plans .plan input[type="radio"]:checked+.plan-content {
  border: 1px solid #e1e2e7;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}



@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked+.plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}

.inspiration a {
  color: #666;
}

@media screen and (max-width: 767px) {

  /* inspiration */
  .inspiration {
    display: none;
  }
}

.forplin:not(.collapsed) {
  color: black;
  background-color: white;
}

.upi input[type="radio"]:checked+.plan-content:after {
  top: 24px !important;
}

.creditcard input[type="radio"]:checked+.plan-content:after {
  top: 24px !important;
}

/******** for table of reports by ************/

ul {
  list-style: none;
}

.main-container-wrapper {
  padding: 15px;
}

.tableforreportsby {
  height: 300px;
  display: flex;
  margin: 15px auto;
  border-radius: 4px;
  flex-direction: column;
  border: 1px solid #D0CCDA;
  -moz-box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.1);
}

.table__header {
  height: 45px;
  display: flex;
  flex: auto 0 0;
  background-color: #f2f2f9;
  border-bottom: 2px solid #D0CCDA;
}

.table__body {
  display: flex;
  overflow: hidden;
  flex: auto;
}

.table__col-group {
  overflow: hidden;
}

.table__col-group--sticky {
  width: 120px;
  background-color: #E2E2E2;
  border-right: 2px solid #D0CCDA;
}

.table__col-group--scrollable {
  width: calc(100% - 120px);
  position: relative;
}

.table__scrollable-header-container::before {
  content: "";
  width: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  background-color: #f2f2f9;
  z-index: 10;
}

.table__header-section--right {
  width: 100%;
  position: relative;
  overflow-x: scroll;
}

.table__header-items {
  display: inline-block;
  font-size: 0;
  white-space: nowrap;
  height: 45px;
  overflow-y: scroll;
}

.table__header-items span {
  font-size: 16px;
}

.table__body-section--left {
  display: inline-block;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}

.table__body-section--right {
  overflow: scroll;
  position: relative;
}

.table__list li {
  font-size: 0;
  border-bottom: 1px solid #c7c7c7;
}

/* .table__list--left {
  width: 140px;
} */

.table__list--right {
  display: inline-block;
  min-width: 100%;
  min-height: calc(100% - 3px);
}

.table__list--right li {
  font-size: 0;
  white-space: nowrap;
}

.table__cell {
  width: 120px;
  height: 45px;
  line-height: 1;
  font-size: 14px;
  overflow: hidden;
  padding: 15px 20px;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

/***** login css *****/
.containerfrol {
  background: linear-gradient(90deg, rgba(199, 197, 244, 1) 0%, rgba(119, 107, 204, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}

.screen {
  background: linear-gradient(90deg, #5D54A4, #7C78B8);
  position: relative;
  height: 600px;
  width: 450px;
  box-shadow: 0px 0px 24px #5C5696;
}



.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape1 {
  height: 520px;
  width: 520px;
  background: #FFF;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: #6C63AC;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #5D54A4, #6A679E);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape4 {
  height: 400px;
  width: 200px;
  background: #7E7BB9;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

.login {
  width: 320px;
  padding: 30px;
  padding-top: 156px;
}

.login__field {
  padding: 20px 0px;
  position: relative;
}

.login__icon {
  position: absolute;
  top: 30px;
  color: #7875B5;
}

.login__input {
  border: none;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 75%;
  transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #6A679E;
}

.login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #D4D3E8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #4C489D;
  box-shadow: 0px 2px 2px #5C5696;
  cursor: pointer;
  transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #6A679E;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875B5;
}

.social-login {
  position: absolute;
  height: 140px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fff;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-login__icon {
  padding: 20px 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
  transform: scale(1.5);
}
#tabs{
	background: #007b5e;
    color: #eee;
}
#tabs h6.section-title{
    color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #f3f3f3;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 4px solid !important;
    font-size: 20px;
    font-weight: bold;
}
#tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #eee;
    font-size: 20px;
}

      /* store login */
.storeScreen{max-height: 95vh; overflow: auto; box-shadow: none; border-radius: 8px;}
.storelogin{background-color: white;}
.storelogin .storeLogo p{cursor: pointer;}
.storelogin .storeLogo p:hover{color: #04AA6D;}
.storelogin .storeHeading p{cursor: pointer;} 
.storelogin .storeHeading p:hover{color: #04AA6D;}
.storeIcon{background-color: #d3d7db;}

      /* user login  */
.userScreen{max-height: 95vh; overflow: auto; border-radius: 8px; background-color: white; width: 30rem;}


  /* ------- for skinny scrollbar START ------- */
  /* scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }
   /* ------- for skinny scrollbar  END ------- */

.storeBox ul{cursor: pointer;}
.storeBox ul:hover{box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;}



.active-inactiveTab .nav-pills .nav-link.active{background-color: #b7bec5;}
.active-inactiveTab nav .myTabAnc{border-bottom: 2px solid transparent; font-size: 1.3rem;}
.active-inactiveTab nav .myTabAnc:hover{border-bottom: 2px solid #28a745;}

      /* on check button option */
.onCheckBtn .myBtn:hover{outline: rgb(200, 196, 196) solid 1px;}
.belowCheckBtn:hover{background-color: #f2f7fe;}
.bellowBtnHomePageIcon{background-color:white; padding: 5px; border-radius: 20%; border: 1px solid rgb(218, 215, 215);}
.onCheckBtn .dropdown-item:active{background-color: #f2f7fe; color: #000;}

      /* for add product */
.add-image .add-image-option {
  border: 1px dashed gray;
  background-color: #f2f7fe;
  height: 7rem;
  width: 100%;
  border-radius: 0.4rem;
}

.add-image .add-image-option:hover {
  background-color: rgb(231, 236, 236);
  cursor: pointer;
}

.add-option .iconTrash i,
.add-option .iconSliders i {
  cursor: pointer;
}

      /* --- collection-online store --- */
.forDot{width: 10px; height: 10px; border-radius: 50%; background-color: #04AA6D; display: inline-block;}
.online-sch-ava{margin-left: 18px;}

      /* collection online store */
.mySchAvaModal-min-H {
  min-height: 70vh;
}

      /* setting modal */
.mySettingModal{max-width: 100% !important; top: 5%;}
.settingBodyModal{background-color: #f6f6f7;}
#settingModal.modal.fade.show{display: block !important; background-color: rgba(0, 0, 0, .5);}
.settingBodyModal .list-group-item.active{background-color: #04AA6D; border: none;}

      /* in Users and permissions -> modal */
.storeOwnerList{padding-left: 4% !important;}
.storeOwnerList li{list-style: disc !important;}

      /* in payment -> add payment methods or for navTabStyle */
.navTabStyle ul{border-bottom: 1px solid rgb(218, 215, 215);}
.navTabStyle ul li a{border-bottom: 3px solid transparent ; padding-bottom: 8px; display: inline-block;}
.navTabStyle ul li a:hover{border-bottom: 3px solid #afb2b1;}
.navTabStyle ul li a:active{border-bottom: 3px solid #04AA6D;}

      /* third party payment provider card size */
.thirdPartyPay div ul li .payCardSize{width: 2.5rem;}

.filterPayMethodscrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  width: 100%;
}
.filterPayMethodscrollable-menu li .dropdown-item:active{background-color: white; color: #000;}

      /* Shipping and delivery*/
.cShippingRate{border: 1px dashed #b7bec5;}
.localShopLocation:hover{background-color: #f8f9fd; border-bottom-left-radius: 3%; border-bottom-right-radius: 3%; text-decoration: underline;text-decoration-color: #000; text-decoration-thickness: 1px;}

.createZoneCountrySelect{ width: 100%; max-height: 20rem; overflow-y: scroll; overflow-x: hidden;}
.editProItemModalBody{min-height: 65vh;}

      /* for controling collapse transition */
.stopTransition.collapsing {
  transition: none !important;
}  

      /* Edit packing slip template */
.packingSlipTem{max-height: 35rem; min-height: 35rem; overflow-x: hidden; overflow-y: scroll;}
.tableRowHover:hover{background-color: #f8f9fa;}
.forOverride .selectCollScrollable-menu{height: auto; max-height: 200px; overflow-x: hidden;}
.addOverrideTaxModalHeight{min-height: 24rem;}
.selectColectionLI:hover{background-color:#f8f9fa; cursor: pointer;}

      /* Markets - alert  */
.marketAlertUL li{list-style-type: disc;}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

 /* Metafields - rows hover color*/
.metafieldRow:hover{background-color: #f8f9fa;}

.seletTypeUl{max-height: 15rem; overflow-x: hidden; overflow-y: scroll;}




  